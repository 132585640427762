@import "../../common/variable.module.scss";

.song_play {
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 1440px) {
      width: 118rem;
      margin-left: 30rem;
    }

    @media (max-width: 1440px) {
      width: 118rem;
      margin-left: -22rem;
    }

    @media (max-width: 1024px) {
      width: 98rem;
      margin-left: -14rem;
    }
  }
}
