@import "common/variable.module.scss";

.dashboard {
  .dashboard_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .tile_tracks {
    display: grid;
    grid-template-columns: 40rem 1fr;
    grid-gap: 2rem;
    color: $theme-text-color;

    @media (max-width: 1024px) {
      grid-template-columns: 25rem 1fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .tile {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .tile_details {
        background-color: $box-bg-color;
        box-shadow: $box-shadow;
        height: 100%;
        border-radius: 1rem;
        padding: 2rem 2rem;
        position: relative;
        color: white;
        cursor: pointer;

        @media (max-width: 1024px) {
          height: 16rem;
          padding: 1rem 2rem;
        }

        &:nth-child(1) {
          background-image: linear-gradient(to right, #253a99, #2b5ceb);
        }

        &:nth-child(2) {
          background-image: linear-gradient(to right, #dd7200, #ff9e20);
        }

        h3 {
          font-size: 2rem;
          font-weight: 500;
          margin-top: 0;

          @media (max-width: 1024px) {
            font-size: 1.8rem;
          }
        }

        svg {
          position: absolute;
          font-size: 4.5rem;
          bottom: 30%;
          left: 8%;

          @media (max-width: 1024px) {
            font-size: 4rem;
          }
        }

        p {
          position: absolute;
          right: 10%;
          top: 50%;
          transform: translateY(-50%);
          font-size: 4.5rem;
          font-weight: 600;

          @media (max-width: 1024px) {
            font-size: 4rem;
            top: 40%;
          }
        }
      }
    }

    .map {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 1rem;
      height: 41rem;
      overflow: hidden;

      @media (max-width: 1024px) {
        height: 34rem;
      }

      .heading {
        padding: 0rem 2rem;
        display: none;
        h3 {
          font-size: 2rem;
          font-weight: 500;
        }
      }

      .map_show {
        height: 100%;

        // iframe {
        //   border: none;
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
  }

  .album_playlist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    color: black;
    margin-top: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .album_list,
    .playlists {
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 1rem;
      padding: 2rem 2rem;

      h3 {
        font-size: 2rem;
        font-weight: 500;

        @media (max-width: 1024px) {
          font-size: 1.8rem;
        }
      }

      .albums,
      .playlist {
        padding: 2rem 0rem;
        display: flex;
        // justify-content: center;
        // align-items: center;
        gap: 4.6rem;
        flex-wrap: wrap;
        position: relative;
        height: 70%;

        .dataNotFound {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 50%;
          left: 50%;
          width: 100%;
          min-height: 15rem;
          transform: translate(-50%, -50%);

          p {
            font-size: 1.6rem;
            color: grey;
          }
        }

        @media (max-width: 1765px) {
          gap: 3rem;
        }

        @media (max-width: 1024px) {
          gap: 3rem;
        }
      }
    }
  }

  .top_track {
    margin-top: 2rem;
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 1rem;
    padding: 2rem 0rem;

    .heading {
      padding: 0rem 2rem;
      h3 {
        font-size: 2rem;
        font-weight: 500;
      }
    }

    .track_table {
      margin-top: 1.5rem;
      overflow-x: auto;

      table {
        width: 100%;
        border-collapse: collapse;
        cursor: pointer;

        th {
          color: $theme-text-color;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1.2rem 3rem;
          text-align: left;
          color: #878787;
          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;
          background-color: rgb(240, 240, 240);
        }

        tbody {
          tr {
            &.dataNotFound {
              text-align: center;
              padding-top: 5rem;
              margin-top: 5rem;
              color: grey;

              .trackDataNotFound {
                p {
                  font-size: 1.6rem;
                  color: grey;
                  padding-top: 1.5rem;
                }
              }
            }

            border-bottom: 0.1rem solid #e1e1e1;

            &:nth-child(even) {
              background-color: #efefef;
            }

            &:last-child {
              border-bottom: none;
            }

            td {
              padding: 1.2rem 3rem;
              font-size: 1.4rem;
              color: #323643;

              &.image_name {
                display: flex;
                align-items: center;
                gap: 2rem;

                .image {
                  width: 3rem;
                  height: 3rem;
                  overflow: hidden;
                  border-radius: 0.5rem;

                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }
              }

              .track_name {
                p {
                  width: 26rem;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }

              .play_div,
              .like_div,
              .duration_div {
                display: flex;
                align-items: center;
                gap: 1rem;
                width: 6rem;
              }
            }
          }
        }
      }
    }
  }
}
