@import '../../common/variable.module.scss';

.approvalPending{
    background-color: $theme-dark-color;
    height: 100dvh;
    width: 100%;

    .main{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .pendingLogo{
            svg{
                font-size: 10rem;
                color: rgb(197, 138, 28);
            }
        }
        .description{
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: 5rem;
            h1{
                text-align: center;
                font-size: 5rem;
                color: white;
            }
            p{
                font-size: 1.8rem;
                max-width: 50rem;
                color: grey;
                text-align: center;
                margin-top: 4rem;
            }
            button{
                border: 0.1rem solid grey;
                background-color: transparent;
                color: white;
                padding: 1rem 4rem;
                border-radius: 10rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: 2rem;
                cursor: pointer;

                svg{
                    transition: all 0.5s;
                }

                &:hover{
                    svg{
                        transform: translateX(0.5rem);
                    }
                }
            }
        }
    }
}