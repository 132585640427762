@import '../css/common/variable.module.scss';

.playlist {
    .playlist_heading {
        margin-bottom: 2rem;

        h2 {
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }
}
