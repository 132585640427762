@import '../../common/variable.module.scss';

.navtab {
    width: 100%;
    overflow: hidden;
    background-color: #eff3f6;

    .deleteAlbum{
        position: fixed;
        z-index: 110;
        left: 0%;
        top: 0%;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.705);
        display: flex;
        align-items: center;
        justify-content: center;

        .innerDeleteAlbum{
            min-width: 35rem;
            max-width: 40rem;
            background-color: white;
            padding: 3rem 4rem;
            border-radius: 1rem;

            .heading{
                font-size: 2rem;
                p{
                    text-align: center;
                }
            }

            .delete_image{
                height: 15rem;
                padding: 2rem;
                width: 100%;
                overflow: hidden;
                text-align: center;
                img{
                    height: 100%;
                }
            }

            .buttons{
                display: flex;
                flex-direction: column;
                gap: 2rem;

                button{
                    border: none;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    cursor: pointer;

                    &:first-child{
                        background-color: #dc546a;
                        color: white;
                    }

                    &:last-child{
                        background-color: #5185ea;
                        color: white;
                    }
                }
            }
        }
    }

    .navbar {
        width: 100%;
        height: 7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #222;
        color: #fff;
        padding: 1rem 3rem;
        font-size: 1.8rem;
        border-radius: 0rem 0rem 1rem 1rem;

        .filters {
            display: flex;
            gap: 3rem;
            cursor: pointer;

            

            .nav_item{

                &.active{
                    color: #fc7a00;
                }
                
            }

        }

        .count {
            display: flex;
            gap: 3rem;

            div{
                display: flex;
                gap: 1rem;
                align-items: center;

                svg{
                    font-size: 1.8rem;
                }

                p{
                    font-size: 1.8rem;
                }

                &.like{
                    svg{
                        // color: red;
                        font-size: 2.2rem !important;
                    }
                }

            }
        }
    }

    .album_songList {

        width: 100%;

        .track_table {
            margin-top: 1.5rem;

            table {
                width: 100%;
                border-collapse: collapse;
                cursor: pointer;
                align-items: center;

                th {
                    color: $theme-text-color;
                    font-size: 1.4rem;
                    font-weight: 500;
                    padding: 1.2rem 3rem;
                    text-align: left;
                    color: #878787;
                    border-top: 1px solid #e1e1e1;
                    border-bottom: 1px solid #e1e1e1;
                    background-color: rgb(240, 240, 240);
                }

                tbody {
                    .dataNotFound{
                        text-align: center;
                        width: 100%;
                    }
                    tr {
                        border-bottom: 0.1rem solid #e1e1e1;

                        &:nth-child(even) {
                            background-color: #efefef;
                        }

                        &:last-child {
                            border-bottom: none;

                            &:last-child{
                                .manage{
                                    .manageTrack{
                                        top: 0%;
                                    }
                                }
                            }
                        }

                        td {
                            padding: 2.2rem 3rem;
                            font-size: 1.4rem;
                            color: #323643;

                            .time_div,
                            .like_div,
                            .play_div{
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                                
                            }


                            .manage{
                                position: relative;
                                
                                .manageTrack{
                                    position: absolute;
                                    width: 17rem;
                                    background-color: white;
                                    border-radius: 0.5rem;
                                    right: 100%;
                                    z-index: 15;
                                    overflow: hidden;
                                    box-shadow: $box-shadow;

                                    p{
                                        font-size: 1.4rem;
                                        color: $theme-dark-color;
                                        padding: 0.8rem 1rem;
                                        border-bottom: 0.1rem solid rgb(221, 221, 221);
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;
                    
                                        span{
                                            svg{
                                                color: $theme-dark-color;
                                            }
                    
                                            &:first-child{
                                                display: flex;
                                                align-items: center;
                                                width: 3rem;
                                            }
                                        }
                    
                                        &:last-child{
                                            border-bottom: none;
                                        }
                    
                                        &:hover{
                                            background-color: rgba(235, 235, 235, 0.753);
                                        }
                                    }

                                }
                            }

                            &.image_name {
                                display: flex;
                                align-items: center;

                                .image {
                                    width: 3rem;
                                    height: 3rem;
                                    overflow: hidden;
                                    border-radius: 0.5rem;

                                    img {
                                        height: 100%;
                                        width: 100%;
                                    }
                                }

                                .track_name {
                                    margin-left: 2rem;
                                }
                            }

                            svg {
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}