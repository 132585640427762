@import "../css/common/variable.module.scss";

.support {
  .support_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .channel_data_tracks {
    width: 100%;
    float: left;
    margin-top: 2rem;
    padding: 2rem 2rem;
    background-color: white;
    border-radius: 0.5rem;

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0rem 0rem 2rem 0rem;

      input,
      select {
        height: 4rem;
        width: 35rem;
        border: none;
        border-radius: 0.5rem;
        padding: 0rem 2rem;
        outline: none;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        cursor: text;
      }

      button {
        border: none;
        background-color: white;
        height: 4rem;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        svg {
          font-size: 2rem;
        }

        &:hover {
          background-color: $theme-dark-color;
          background-color: rgb(233, 233, 233);
        }
      }
    }

    .channel_data_table {
      // margin-top: 1.5rem;
      overflow-x: auto;

      table {
        width: 100%;
        border-collapse: collapse;
        align-items: center;

        th {
          color: $theme-text-color;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1.2rem 3rem;
          text-align: left;
          color: #878787;
          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;
          background-color: rgb(240, 240, 240);
        }

        .issue_description {
          width: 40rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        tbody {
          .dataNotFound {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1.8rem;
            color: #878787;
            padding-top: 1.5rem;
          }

          tr {
            border-bottom: 0.1rem solid #e1e1e1;

            &:nth-child(even) {
              background-color: #efefef;
            }

            &:last-child {
              border-bottom: none;

              .manageCategory {
                bottom: 100%;
              }
            }

            td {
              padding: 2.2rem 3rem;
              font-size: 1.4rem;
              color: #323643;

              .issue_description {
                p {
                  width: 40rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &.delete_track {
                svg {
                  color: red;
                  cursor: pointer;
                }
              }

              svg {
                font-size: 1.8rem;
              }
            }

            .edit_language {
              cursor: pointer;
            }
          }
        }
      }
    }

    .add_normal_track {
      padding: 0rem 2rem;

      .track_heading {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        h2 {
          font-size: 2rem;
          font-weight: 500;
          color: rgb(97, 97, 97);
        }
      }

      .trackAdd {
        width: 100%;
        background-color: white;
        border-radius: 1rem;
        min-height: 100%;
        padding: 0rem;

        .form_heading {
          position: relative;
          height: 4rem;

          h3 {
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
            line-height: 4rem;
            color: $theme-dark-color;
          }

          button {
            position: absolute;
            left: 0%;
            top: 0%;
            border: none;
            background-color: white;
            height: 100%;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
            transition: all 0.2s;

            svg {
              font-size: 2rem;
            }

            &:hover {
              background-color: rgb(233, 233, 233);
            }
          }
        }

        .add_form {
          margin-top: 2rem;
          margin-bottom: 2%;

          .form_grid {
            .form_field {
              display: flex;
              flex-direction: column;

              label {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 4.5rem;
                padding-top: 2rem;
              }

              textarea {
                width: 100%;
                resize: none;
                height: 10rem;
                padding: 1rem 1.2rem;
                border-radius: 0.5rem;
                border: 1px solid #e0e0e0;
                font-size: 1.6rem;
                outline: none;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

                &:focus {
                  outline: 0.1rem solid $theme-dark-color;
                }

                &::-webkit-scrollbar {
                  width: 0.5rem;
                  background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  background-color: white;
                }
              }
            }
          }

          .buttons {
            margin-top: 5rem;
            display: flex;
            gap: 2rem;

            button {
              padding: 0rem 2rem;
              height: 5rem;
              cursor: pointer;
              font-weight: 500;
              font-size: 1.8rem;
              border-radius: 0.5rem;
              background-color: white;
              box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
              transition: all 0.3s;
              border: 0.2rem solid transparent;

              &.action {
                background-color: #01928d;
                color: white;

                svg {
                  font-size: 2rem;
                  animation: loading 2s ease infinite;
                  @keyframes loading {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }

              &.cancel {
                border: 0.1rem solid #01928d;
              }

              &:hover {
                &.action {
                  background-color: #006e6b;
                }

                &.cancel {
                  background-color: rgb(245, 245, 245);
                }
              }
            }
          }
        }
      }
    }
  }
}
