@import '../common/variable.module.scss';

.error{
    background-color: rgb(199, 199, 199);
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    h2{
        font-size: 10rem;
        font-weight: 600;
        letter-spacing: 1rem;
        line-height: 10rem;
    }

    p{
        font-size: 2rem;
    }

    button{
        font-size: 1.8rem;
        padding: 1rem 3rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        border: none;
        background-color: $theme-dark-color;
        color: white;
        cursor: pointer;
        
        &:hover{
            svg{
                transform: translateX(0.5rem);
            }
        }
        
        svg{
            transition: all 0.5s;
        }
    }
}