@import '../../common/variable.module.scss';

.playlistView {
  overflow: hidden;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  float: left;

  .playlistHeading{
    height: 100%;
    width: 100%;
  }

  .playlistTrack{
    margin-top: 0rem;
  }
}