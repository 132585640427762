@import "../../common/variable.module.scss";

.playlists {
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  min-height: 100%;
  padding: 4rem;

  .filter_search_btn {
    display: flex;
    justify-content: space-between;

    input,
    select {
      height: 4rem;
      border: none;
      border-radius: 0.5rem;
      padding: 0rem 2rem;
      outline: none;
      box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
      cursor: text;
    }

    button {
      border: none;
      background-color: white;
      height: 4rem;
      padding: 0rem 2rem;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
      transition: all 0.2s;

      svg {
        font-size: 2rem;
      }

      &:hover {
        background-color: $theme-dark-color;
        background-color: rgb(233, 233, 233);
      }
    }

    .filter_sort {
      display: flex;
      justify-content: center;
      gap: 1rem;

      .filter {
        position: relative;

        .filter_dropdown {
          width: 40rem;
          position: absolute;
          left: 0%;
          top: 120%;
          z-index: 10;
          background-color: white;
          border-radius: 1rem;
          overflow: hidden;
          box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem grey;
          padding-bottom: 1rem;

          label {
            font-size: 1.5rem;
          }

          .heading_clear {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              font-size: 1.4rem;

              &:first-child {
                color: #8d8d8d;
              }

              &:last-child {
                color: #002ee4;
                cursor: pointer;
              }
            }
          }

          .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f9f9f9;
            padding: 1rem 2rem;
            border-bottom: 0.1rem solid #e7e7e7;

            p {
              font-size: 1.6rem;
              font-weight: 500;
            }

            svg {
              font-size: 1.8rem;
              cursor: pointer;
            }
          }

          .selectDate {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 2rem;

            .filter_date {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .from,
              .to {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              }
            }
          }

          .selectStatus {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 2rem;
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 2rem;
            // margin-top: 1rem;

            button {
              font-size: 1.4rem;

              &:last-child {
                background-color: #002ee4;
                color: white;
              }
            }
          }
        }
      }

      .sort {
        position: relative;

        .sort_dropdown {
          width: 20rem;
          position: absolute;
          left: 0%;
          top: 120%;
          z-index: 10;
          background-color: white;
          border-radius: 1rem;
          overflow: hidden;
          box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem grey;

          p {
            font-size: 1.5rem;
            display: flex;
            justify-content: space-between;
            padding: 0rem 2rem;
            border-bottom: 0.1rem solid #e7e7e7;
            line-height: 4rem;
            transition: all 0.2s;
            cursor: pointer;

            &:hover {
              background-color: #ececec;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      gap: 1rem;
      input {
        width: 35rem;
      }
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 3rem;
    margin-top: 4rem;

    .dataNotFound {
      width: 100%;
      text-align: center;
      // font-size: 2rem;
      font-size: $data-not-found-font-size;
      color: $data-not-found-font-color;
    }
  }
}
