@import '../../common/variable.module.scss';

.album_heading {
    width: 100%;
    height: 35rem;
    position: relative;

    .deleteAlbum {
        position: fixed;
        z-index: 110;
        left: 0%;
        top: 0%;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.705);
        display: flex;
        align-items: center;
        justify-content: center;
    
        .innerDeleteAlbum {
            min-width: 35rem;
            max-width: 40rem;
            background-color: white;
            padding: 3rem 4rem;
            border-radius: 1rem;
    
            .heading {
                font-size: 2rem;
    
                p {
                    text-align: center;
                }
            }
    
            .delete_image {
                height: 15rem;
                padding: 2rem;
                width: 100%;
                overflow: hidden;
                text-align: center;
    
                img {
                    height: 100%;
                }
            }
    
            .buttons {
                display: flex;
                flex-direction: column;
                gap: 2rem;
    
                button {
                    border: none;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    cursor: pointer;
    
                    &:first-child {
                        background-color: #dc546a;
                        color: white;
                    }
    
                    &:last-child {
                        background-color: #5185ea;
                        color: white;
                    }
                }
            }
        }
    }

    .image {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }
    }

    .dark_layout {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(to bottom, #ffffff3d, #000000e5);
        z-index: 2;
    }

    .album_back_manage {
        position: absolute;
        top: 2rem;
        width: 100%;
        z-index: 3;
        padding: 0rem 2rem;
        display: flex;
        justify-content: space-between;
        z-index: 11;

        svg{
            color: white;
            font-size: 2rem;
            cursor: pointer;
        }

        .manage{
            position: relative;

            .manage_dropDown{
                position: absolute;
                top: 100%;
                right: 100%;
                border-radius: 0.5rem;
                background-color: white;
                width: 15rem;
                overflow: hidden;

                p{
                    font-size: 1.6rem;
                    color: $theme-dark-color;
                    padding: 0.7rem 1rem;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    span{
                        svg{
                            color: $theme-dark-color;
                        }

                        &:first-child{
                            display: flex;
                            align-items: center;
                            width: 3rem;
                        }
                    }

                    &:last-child{
                        border-bottom: none;
                    }

                    &:hover{
                        background-color: rgb(209, 209, 209);
                    }
                }
            }
        }



        .icons {
            color: white;
            font-size: 3rem;
            cursor: pointer;

            display: flex;
            justify-content: space-between;
        }
    }

    .album_description {
        position: absolute;
        bottom: 3rem;
        left: 0%;
        z-index: 10;
        width: 100%;
        padding: 0rem 3rem;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .artist_info {
            width: 100%;
            display: flex;
            gap: 2rem;

            .artist_image {
                width: 35rem;
                height: 20rem;
                border-radius: 1rem;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .album_details {
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                h3 {
                    font-size: 3rem;
                    font-weight: 600;
                }

                p {
                    margin-top: 0.5rem;
                    font-size: 1.4rem;
                    padding-right: 8rem;
                    color: #ACACAC;
                    width: 50rem;
                }
            }
        }

        .play {
            cursor: pointer;
            align-self: flex-end;
            padding-right: 3rem;
            
            svg{
                color: white;
                font-size: 7rem;
            }
        }
    }
}