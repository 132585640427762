@import '../../common/variable.module.scss';

.loginMain{
    background-color: $theme-dark-color;
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .loginBg{
        background-color: $theme-light-color;
        min-width: 50rem;
        max-width: 50rem;
        width: fit-content;
        padding: 3rem 4rem;
        border-radius: 1rem;

        @media(max-width: 600px){
            min-width: 90%;
        }

        @media(max-width: 500px){
            padding: 3rem 2rem;
        }

        .logo{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            justify-content: center;

            img{
                width: 40%;
            }

            h3{
                font-size: 3rem;
                color: white;
                font-weight: 600;
            }
        }

        .loginForm{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            margin-top: 3rem;

            .inputField{

                div{
                    position: relative;

                    svg{
                        position: absolute;
                        font-size: 2rem;
                        right: 1.5rem;
                        color: white;
                        cursor: pointer;
                        z-index: 2;
                        bottom: 50%;
                        transform: translateY(80%);
                    }

                }

                label{
                    color: white;
                    font-size: 1.8rem;
                }
                
                input{
                    margin-top: 1rem;
                    font-size: 1.6rem;
                    padding: 1.2rem 2rem;
                    border: 0.1rem solid #939393;
                    border-radius: 1rem;
                    background-color: transparent;
                    outline: none;
                    width: 100%;
                    color: white;
                }

                p{
                    color: red;
                    margin-left: 1rem;
                    margin-top: 1rem;
                    font-size: 1.2rem;
                    position: relative;

                    &::before{
                        content: "";
                        margin-top: 0.5rem;
                        margin-right: 0.2rem;
                    }
                }
            }

            .forgotPassword{
                font-size: 1.6rem;
                text-align: right;
                
                a{
                    font-weight: 400;
                    text-decoration: underline;
                    color: white;
                }
            }

            .button{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;

                button{
                    width: 100%;
                    height: 5rem;
                    border: none;
                    font-size: 1.8rem;
                    font-weight: 600;
                    border-radius: 1rem;
                    background-color: transparent;
                    background-color: white;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg{
                        animation: loading 2s ease infinite;

                        @keyframes loading {
                            0%{transform: rotate(0deg)}
                            100%{transform: rotate(360deg);}
                        }
                    }
                }

                p{
                    font-size: 1.4rem;
                    color: white;
                    text-align: center;

                    a{
                        color: white;
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}