@import "../../common/variable.module.scss";

.album {
  position: relative;
  //   width: 50%;
  flex: 1 1 30rem;
  //   max-width: 33rem;
  height: 21rem;
  background-color: $box-bg-color;
  box-shadow: $box-shadow;
  border-radius: 1rem;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    .sub_details {
      right: 0%;
    }

    .image {
      transform: scale(1.1);
    }
  }

  .image {
    position: absolute;
    height: 100%;
    width: 100%;
    transition: transform 0.7s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dark_layout {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, #ffffff3d, #000000e5);
    z-index: 1;
  }

  .title_description {
    position: absolute;
    bottom: 0%;
    padding: 2rem;
    width: 100%;
    color: white;
    z-index: 2;

    h3 {
      font-size: 2.2rem;
      font-weight: 600;
    }

    p {
      margin-top: 0.5rem;
      font-size: 1.4rem;
      padding-right: 8rem;
      color: #acacac;

      width: 40rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sub_details {
    position: absolute;
    right: -12rem;
    top: 0%;
    height: 100%;
    width: 12rem;
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 4;
    transition: right 0.5s;

    .album_type {
      color: white;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      width: fit-content;
      background-color: #454545;
      padding: 0.5rem 0.8rem;
      border-radius: 0.5rem;

      svg {
        font-size: 1.4rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    .info {
      display: flex;
      width: 100%;
      gap: 1rem;
      flex-direction: column;
      padding-left: 2rem;

      svg {
        font-size: 1.6rem;
        color: #acacac;
      }

      p {
        font-size: 1.4rem;
        color: #acacac;
      }

      div {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
