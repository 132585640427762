@import 'common/variable.module.scss';

.profile {

    .profile_heading{
        margin-bottom: 2rem;

        h2{
            font-size: 2rem;
            font-weight: 500;
            color: rgb(97, 97, 97);
        }
    }

    .profile_box{
        background-color: $box-bg-color;
        box-shadow: $box-shadow;
        height: 100%;
        border-radius: 1rem;
    
        .profile_manage{
            padding: 2rem 4rem;
    
            .profile_info{
                font-size: 3rem;
                font-weight: 600;
                padding-bottom: 1.5rem;
            }

            hr{
                border: none;
                background-color: rgba(199, 199, 199, 0.959);
            }
    
            .profile_picture_edit {
                margin-top: 3rem;
                margin-bottom: 3rem;
                width: 20rem;
                height: 20rem;
                border-radius: 50%;
                overflow: hidden;
                position: relative;
              
                img {
                  position: absolute;
                  overflow: hidden;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              
                .camera_icon {
                  position: absolute;
                  width: 100%;
                  height: 20%;
                  bottom: 0%;
                  background-color: rgba(0, 0, 0, 0.904);
                  z-index: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
    
                  svg{
                    color: white;
                    font-size: 1.8rem;
                    cursor: pointer;
                  }
                }
            }
    
            .information{
                margin-top: 2rem;
    
                p{
                    font-size: 2.5rem;
                    font-weight: 600;
                    margin-top: 6rem;
                }
    
                .sub_information{
                    // display: flex;
                    // flex-direction: row;
                    // gap: 2rem;
                    width: 100%;
                    margin-bottom: 1.5rem;

                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 2rem;
    
                    .information_field{
                        display: flex;
                        flex-direction: column;
    
                        label{
                            font-size: 1.6rem;
                            font-weight: 500;
                            line-height: 4.5rem;
                        }
    
                        input, textarea, select{
                            // width: 75rem;
                            height: 5rem;
                            border-radius: 0.5rem;
                            border: 1px solid #e0e0e0;
                            font-size: 1.6rem;
                            padding: 0rem 2rem;
                            outline: none;
                            box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);

                            &:focus{
                                outline: 0.1rem solid $theme-dark-color;
                            }
                        }
    
                        textarea{
                            resize: none;
                            height: 10rem;
                            padding: 1rem 1.2rem;
                        }
    
                        span{
                            color: red;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
    
            .profile_btn{
                display: flex;
                justify-content: center;
                margin-top: 5rem;
                margin-bottom: 3rem;
                
                button{
                    width: 25rem;
                    padding: 0rem 2rem;
                    height: 5rem;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 1.8rem;
                    border-radius: 0.5rem;
                    background-color: white;
                    box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                    transition: all 0.3s;
                    border: 0.2rem solid transparent;

                    svg{
                        animation: loading 2s ease infinite;

                        @keyframes loading {
                            0%{transform: rotate(0deg)}
                            100%{transform: rotate(360deg);}
                        }
                    }

                    &:hover {
                        background-color: rgb(245, 245, 245);
                    }
                }
            }
    
            hr{
                border-bottom: $hr-border-bottom;
            }
        }
    }
}