@import '../../common/variable.module.scss';

.albumView {
  overflow: hidden;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  float: left;

  .albumHeading{
    height: 100%;
    width: 100%;
  }

  .albumTrack{
    margin-top: 0rem;
  }
}