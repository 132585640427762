@import "../css/common/variable.module.scss";

.track {
  .deleteTrack {
    position: fixed;
    z-index: 110;
    left: 0%;
    top: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.705);
    display: flex;
    align-items: center;
    justify-content: center;

    .innerDeleteTrack {
      min-width: 35rem;
      max-width: 40rem;
      background-color: white;
      padding: 3rem 4rem;
      border-radius: 1rem;

      .heading {
        font-size: 2rem;
        p {
          text-align: center;
        }
      }

      .delete_image {
        height: 15rem;
        padding: 2rem;
        width: 100%;
        overflow: hidden;
        text-align: center;
        img {
          height: 100%;
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        button {
          border: none;
          padding: 1rem;
          border-radius: 0.5rem;
          cursor: pointer;

          svg {
            animation: loading 2s ease infinite;

            @keyframes loading {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }

          &:first-child {
            background-color: #dc546a;
            color: white;
          }

          &:last-child {
            background-color: #5185ea;
            color: white;
          }
        }
      }
    }
  }

  .track_heading {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
  }

  .track_nav {
    width: 100%;
    background-color: white;
    border-radius: 1rem;
    min-height: 100%;
    padding: 4rem;

    .filter_search_btn {
      display: flex;
      justify-content: space-between;

      input,
      select {
        height: 4rem;
        border: none;
        border-radius: 0.5rem;
        padding: 0rem 2rem;
        outline: none;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        cursor: text;
      }

      button {
        border: none;
        background-color: white;
        height: 4rem;
        padding: 0rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
        transition: all 0.2s;

        svg {
          font-size: 2rem;
        }

        &:hover {
          background-color: $theme-dark-color;
          background-color: rgb(233, 233, 233);
        }
      }

      .filter_sort {
        display: flex;
        justify-content: center;
        gap: 1rem;

        .filter {
          position: relative;

          .filter_dropdown {
            width: 40rem;
            position: absolute;
            left: 0%;
            top: 120%;
            z-index: 10;
            background-color: white;
            border-radius: 1rem;
            overflow: hidden;
            box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem grey;
            padding-bottom: 1rem;

            label {
              font-size: 1.5rem;
            }

            .heading_clear {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                font-size: 1.4rem;

                &:first-child {
                  color: #8d8d8d;
                }

                &:last-child {
                  color: #002ee4;
                  cursor: pointer;
                }
              }
            }

            .heading {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #f9f9f9;
              padding: 1rem 2rem;
              border-bottom: 0.1rem solid #e7e7e7;

              p {
                font-size: 1.6rem;
                font-weight: 500;
              }

              svg {
                font-size: 1.8rem;
                cursor: pointer;
              }
            }

            .selectDate {
              margin-top: 2rem;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              padding: 1rem 2rem;

              .filter_date {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .from,
                .to {
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;

                  // input{
                  //     cursor: pointer;
                  // }
                }
              }
            }

            .selectStatus {
              margin-top: 1rem;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              padding: 1rem 2rem;
            }

            .buttons {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1rem 2rem;

              button {
                font-size: 1.4rem;

                &:last-child {
                  background-color: #002ee4;
                  color: white;
                }
              }
            }
          }
        }

        .sort {
          position: relative;

          .sort_dropdown {
            width: 20rem;
            position: absolute;
            left: 0%;
            top: 120%;
            z-index: 10;
            background-color: white;
            border-radius: 1rem;
            overflow: hidden;
            box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem grey;

            p {
              font-size: 1.5rem;
              display: flex;
              justify-content: space-between;
              padding: 0rem 2rem;
              border-bottom: 0.1rem solid #e7e7e7;
              line-height: 4rem;
              transition: all 0.2s;
              cursor: pointer;

              &:hover {
                background-color: #ececec;
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        gap: 1rem;
        input {
          width: 35rem;
        }
      }
    }
  }

  .track_songList {
    width: 100%;

    .track_table {
      margin-top: 1.5rem;

      table {
        width: 100%;
        border-collapse: collapse;
        cursor: pointer;
        align-items: center;

        th {
          color: $theme-text-color;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1.2rem 3rem;
          text-align: left;
          color: #878787;
          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;
          background-color: rgb(240, 240, 240);
        }

        tbody {
          .dataNotFound {
            text-align: center;
            width: 100%;

            p {
              font-size: 1.6rem;
              color: grey;
            }
          }
          tr {
            height: 5rem;
            border-bottom: 0.1rem solid #e1e1e1;

            &:nth-child(even) {
              background-color: #efefef;
            }

            &:last-child {
              border-bottom: none;
            }

            td {
              padding: 2.2rem 3rem;
              font-size: 1.4rem;
              color: #323643;

              .time_div,
              .like_div,
              .play_div {
                display: flex;
                align-items: center;
                gap: 1rem;
              }

              .manage {
                position: relative;

                .manageTrack {
                  position: absolute;
                  width: 12rem;
                  background-color: white;
                  border-radius: 0.5rem;
                  right: 100%;
                  z-index: 15;
                  overflow: hidden;
                  box-shadow: $box-shadow;

                  p {
                    font-size: 1.4rem;
                    color: $theme-dark-color;
                    padding: 0.8rem 1rem;
                    border-bottom: 0.1rem solid rgb(221, 221, 221);
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    span {
                      svg {
                        color: $theme-dark-color;
                      }

                      &:first-child {
                        display: flex;
                        align-items: center;
                        width: 3rem;
                      }
                    }

                    &:last-child {
                      border-bottom: none;
                    }

                    &:hover {
                      background-color: rgba(235, 235, 235, 0.753);
                    }
                  }
                }
              }

              &.image_name {
                display: flex;
                align-items: center;

                .image {
                  min-width: 3rem;
                  max-width: 3rem;
                  height: 3rem;
                  overflow: hidden;
                  border-radius: 0.5rem;

                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }

                .track_name {
                  margin-left: 2rem;

                  p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 40rem;
                    min-width: 20rem;

                    @media (max-width: 1500px) {
                      max-width: 30rem;
                    }

                    @media (max-width: 1400px) {
                      max-width: 20rem;
                    }
                  }
                }
              }

              svg {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}
