@import "../common/variable.module.scss";

.list {
  min-width: 22rem;
  max-width: 22rem;
  height: 22rem;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.5s;
  position: relative;
  cursor: pointer;
  box-shadow: 0.1rem 0.1rem 0.5rem grey;
  overflow: hidden;

  @media (max-width: 1724px) {
    min-width: 19rem;
    max-width: 19rem;
    height: 19rem;
  }

  @media (max-width: 1662px) {
    min-width: 18rem;
    max-width: 18rem;
    height: 18rem;
  }

  @media (max-width: 1599px) {
    min-width: 17rem;
    max-width: 17rem;
    height: 17rem;
  }

  @media (max-width: 1536px) {
    min-width: 16rem;
    max-width: 16rem;
    height: 16rem;
  }

  @media (max-width: 1475px) {
    min-width: 15rem;
    max-width: 15rem;
    height: 15rem;
  }

  @media (max-width: 1412px) {
    min-width: 14rem;
    max-width: 14rem;
    height: 14rem;
  }

  @media (max-width: 1350px) {
    min-width: 13rem;
    max-width: 13rem;
    height: 13rem;
  }

  @media (max-width: 1286px) {
    min-width: 12rem;
    max-width: 12rem;
    height: 12rem;
  }

  @media (max-width: 1279px) {
    min-width: 16rem;
    max-width: 16rem;
    height: 16rem;
  }

  @media (max-width: 1234px) {
    min-width: 15rem;
    max-width: 15rem;
    height: 15rem;
  }

  @media (max-width: 1172px) {
    min-width: 14rem;
    max-width: 14rem;
    height: 14rem;
  }

  @media (max-width: 1110px) {
    min-width: 13rem;
    max-width: 13rem;
    height: 13rem;
  }

  @media (max-width: 1046px) {
    min-width: 12rem;
    max-width: 12rem;
    height: 12rem;
  }

  @media (max-width: 984px) {
    min-width: 11rem;
    max-width: 11rem;
    height: 11rem;
  }

  @media (max-width: 921px) {
    min-width: 10rem;
    max-width: 10rem;
    height: 10rem;
  }

  @media (max-width: 859px) {
    min-width: 9rem;
    max-width: 9rem;
    height: 9rem;
  }

  @media (max-width: 796px) {
    min-width: 8rem;
    max-width: 8rem;
    height: 8rem;
  }

  @media (max-width: 768px) {
    min-width: 18rem;
    max-width: 18rem;
    height: 18rem;
  }

  @media (max-width: 670px) {
    min-width: 17rem;
    max-width: 17rem;
    height: 17rem;
  }

  @media (max-width: 640px) {
    min-width: 16rem;
    max-width: 16rem;
    height: 16rem;
  }

  @media (max-width: 609px) {
    min-width: 15rem;
    max-width: 15rem;
    height: 15rem;
  }

  @media (max-width: 577px) {
    min-width: 14rem;
    max-width: 14rem;
    height: 14rem;
  }

  @media (max-width: 320px) {
    min-width: 11rem;
    max-width: 11rem;
    height: 11rem;
  }

  &:hover {
    transform: translateY(-1rem);

    .cover_photo {
      filter: blur(5px);
    }

    .details {
      top: 0%;
    }
  }

  .cover_photo {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center top;
      height: 100%;
      width: 100%;
    }
  }

  .dark_cover {
    position: absolute;
    background-color: rgb(0, 0, 0);
    filter: blur(105px);
    height: 100%;
    width: 100%;
    z-index: 10;
    display: none;
  }

  .details {
    background-color: rgba(0, 0, 0, 0.224);
    position: absolute;
    top: 100%;
    left: 0%;
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.6s;
    border-radius: 1rem;

    h3 {
      font-size: 1.6rem !important;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 1.4rem !important;
      }
    }

    p {
      font-size: 1.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;

      @media (max-width: 1024px) {
        font-size: 1.2rem;
      }

      svg {
        font-size: 2rem;

        @media (max-width: 1024px) {
          font-size: 1.8rem;
        }
      }
    }
  }
}
