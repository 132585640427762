@import "../css/common/variable.module.scss";

.song{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 11;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    
    .song_background{
        background-color: white;
        width: 50rem;
        border-radius: 1.2rem;

        .song_heading{
            color: $theme-dark-color;
            background-color: #f9f9f9;
            border-radius: 1rem 1rem 0rem 0rem ;
            border-bottom: 0.1rem solid #e7e7e7;
            padding: 1rem 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p, svg{
                font-size: 1.8rem;
                font-weight: 600;
            }

            svg{
                cursor: pointer;
            }
        }
    
        .search_song{
            padding: 1rem 2rem;
            position: relative;

            .resultSongs{
                position: absolute;
                border-radius: 1rem;
                // background-color: red;
                background-color: white;
                box-shadow: 0.1rem 0.1rem 0.5rem grey;
                top: 100%;
                width: 90%;
                min-height: 15rem;
                max-height: 25rem;
                overflow: auto;
                // padding: 1rem 2rem;
                left: 50%;
                transform: translateX(-50%);

                .resultHeading{
                    background-color: rgb(228, 228, 228);
                    padding: 1rem 2rem;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    svg{
                        cursor: pointer;
                    }

                }

                .notFound{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 1.4rem;
                    color: grey;
                }

                .particularSearchSong{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 0.1rem solid grey;
                    padding: 1rem 2rem;

                    &:last-child{
                        border-bottom: none;
                    }

                    p{
                        font-size: 1.5rem;
                        width: 80%;
                    }

                    button{
                        border: 0.1rem solid black;
                        border-radius: 50%;
                        height: 2rem;
                        width: 2rem;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
    
            label{
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 4.5rem;
                padding-top: 2rem;
            }
    
            input{
                width: 100%;
                height: 5rem;
                border-radius: 0.5rem;
                border: 1px solid #e0e0e0;
                font-size: 1.6rem;
                margin-top: 2rem;
                margin-bottom: 2rem;
                padding: 0rem 2rem;
                outline: none;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
    
                &:focus{
                    outline: 0.1rem solid $theme-dark-color;
                }
    
            }
        }

        .track_list{
            padding: 1rem 3rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .dummy_track{
                display: flex;
                justify-content: space-between;
                align-items: center;

                p{
                    font-size: 1.4rem;
                }

                svg{
                    font-size: 1.6rem;
                    cursor: pointer;
                    color: red;
                }

            }
        }

        .add_btn{
            padding: 1rem 2rem;
            display: flex;
            justify-content: center;
            padding-bottom: 2.5rem;
            margin-top: 2rem;

            button {
                border: none;
                background-color: white;
                height: 4rem;
                padding: 0rem 2rem;
                border-radius: 0.5rem;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;
                box-shadow: 0.1rem 0.1rem 0.5rem rgba(128, 128, 128, 0.37);
                transition: all 0.2s;
    
                &:hover {
                    background-color: $theme-dark-color;
                    background-color: rgb(233, 233, 233);
                }
            }
        }
    }
}