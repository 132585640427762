.popUp{
    position: fixed;
    z-index: 150;
    right: -45rem;
    // right: 2rem;
    top: 5rem;
    width: 40rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    border-radius: 0.5rem;
    padding: 1rem 1rem;
    background-color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem grey;
    transition: all 1s;

    &.error{
        background-color: #FEE2DF;
    }

    &.success{
        background-color: #B9DFC6;
    }

    &.on{
        right: 2rem;
    }

    .check{
        svg{
            font-size: 2rem;
            &.success{
                color: #0AAC57;
            }
            &.error{
                color: #F04333;
            }
        }
    }

    .message{
        h3{
            font-weight: 500;
            font-size: 1.6rem;
        }
        p{
            font-size: 1.2rem;
        }
    }

    .close{
        position: absolute;
        right: 0%;
        top: 1rem;
        right: 1rem;

        svg{
            font-size: 2rem;
            cursor: pointer;
        }
    }

    .line{
        position: absolute;
        bottom: 0%;
        left: 0%;
        height: 0.5rem;
        width: 100%;
        
        &.error{
            background-color: #F04333;
        }
        
        &.success{
            background-color: #0AAC57;
        }
    }
}