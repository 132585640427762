@import '../common/variable.module.scss';

.sidebar{
    position: fixed;
    left: 0%;
    top: 0;
    height: 100dvh;
    width: $sidebar-width;
    background-color: $sidebar-bg-color;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: left 0.3s;
    z-index: 110;

    @media (max-width: 1280px) {
      left: -$sidebar-width;
    }

    &.active {
        left: -$sidebar-width;
        left: 0%;

    }

    .logo{
        display: flex;
        background-color: $sidebar-logo-bg-color;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: $navbar-height;
        border-bottom: 0.1rem solid rgb(82, 82, 82);
        position: relative;

        svg{
          position: absolute;
          right: 4%;
          font-size: 1.5rem;
          color: white;
          display: none;

          @media (max-width: 1280px) {
            display: block;
          }
        }

        img{
            height: 60%;
        }
    }

    .menu{
        overflow: auto;
        flex-grow: 1;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        &::-webkit-scrollbar{
            width: 5px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: white;
        }

        .submenu{
            text-decoration: none;
            color: $sidebar-font-color;
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 0rem 1rem 0rem 1.5rem;
            border-radius: 0.5rem;
            height: 4rem;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.3s;
            
            &.active {
              background-color: $sidebar-active-color;
            }

            &:hover {
                background-color: $sidebar-hover-bg-color;
                color: $sidebar-hover-font-color;
            }

            .sidebarIcon {
                width: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                
                svg{
                    font-size: $sidebar-icon-size;
                }

                img{
                    width: 2rem;
                    color: white;
                }
            }

            p{
              font-size: $sidebar-font-size;
            }
        }
    }
}

// @media (max-width: 768px) {
//   .sidebar {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 240px;
//     height: 100%;
//     background-color: #333;
//     transition: transform 0.3s ease, width 0.3s ease;
//   }

//   .navbar {
//     width: 100%;

//     &.expanded {
//       width: 100%;
//     }
//   }
// }
